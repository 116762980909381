export const base = {
  computed: {},
  methods: {
    setBreadCrumbs(items) {
      return (this.$store.state.bcItems = items);
    },
    showPopup(status, msg, link, copy) {
      this.$emit("submitted");
      this.$root.$emit("popup", status, msg, link, copy);
    },
    showPopupError(status, msg, link, copy) {
      this.$emit("submitted");
      this.$root.$emit("popup-error", status, msg, link, copy);
    },
    showPopupWithBtn(status, msg,text_link,link) {
      this.$emit("submitted");
      this.$root.$emit("popup-btn", status, msg, text_link, link);
    },
    exportTableToCsv(table, sheetName) {
      const XLSX = require("xlsx");
      let wb = XLSX.utils.table_to_book(table, { sheet: "sheet1" });
      XLSX.writeFile(wb, sheetName + new Date() + ".xlsx");
    },

     request_headers() {
      const theCookie =  document.cookie
        .split(";")
        .map((coki) => coki.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"];

        if ( theCookie !== undefined){
          return {
            "x-api-key":
              "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
            Authorization: `Bearer ${JSON.parse(theCookie).token}`,
            "appplatform": 'vue'
          }; 
        }
    },

  },
};

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes'
import {store} from './store'
import'./assets/font.ttf'
import BreadcrumbsComponent from '@/components/Base/BreadcrumbsComponent.vue'
import AppBar from '@/components/Base/AppBar.vue'
import { PageComponentMixin } from '@/mixins/page'
import PageComponent from '@/components/Base/PageComponent.vue'
import Popup from '@/components/Base/Popup.vue'
import PopupError from '@/components/Base/PopupError.vue'
import PopupErrorBtnComponent from '@/components/Base/PopupWithBtn.vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import VueChatScroll from 'vue-chat-scroll'


Router.prototype.$store = store;

Vue.use(Vuex)
Vue.use(VueChatScroll)
Vue.component('breadcrumbs-component', BreadcrumbsComponent)
Vue.component('page-component', PageComponent)
Vue.component('app-bar', AppBar)
Vue.component('popup-component', Popup)
Vue.component('popup-error-component', PopupError)
Vue.component('popup-error-btn-component', PopupErrorBtnComponent)
// Vue.component('filter-rate', FilterRates)
Vue.mixin(PageComponentMixin)


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-app style="background-color: #f3f5f7">
    <template>
      <v-app-bar
        v-if="$route.name != 'SignIn'"
        style="position: absolute; top: 0"
        id="app_bar"
        app
        :color="$vuetify.theme.themes.light.color1"
        elevation="5"
        height="56"
      >
        <v-spacer class="top-spacer" />


        <v-menu offset-y nudge-top="-74" class="nav-avatar-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar class="ml-sm-4 mr-sm-9" size="40px" v-bind="attrs" v-on="on">
              <img src="@/assets/account.svg" alt="John" />
            </v-avatar>
          </template>
          <v-list>
            <v-list-item class="pl-12" style="border-bottom: 2px solid #ededed"
              >اهلا بك

              {{ customer_name }}
              <v-list-item-icon class="ml-2">
                <img
                  src="@/assets/small-hands.png"
                  class="mr-2"
                  alt="" /></v-list-item-icon
            ></v-list-item>

            <v-list-item class="pl-12" @click="logout">
              <v-list-item-icon class="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.074"
                  height="15.58"
                  viewBox="0 0 17.074 15.58"
                >
                  <g id="logout" transform="translate(0 -22.397)">
                    <g
                      id="Group_1006"
                      data-name="Group 1006"
                      transform="translate(4.975 28.398)"
                    >
                      <g id="Group_1005" data-name="Group 1005">
                        <path
                          id="Path_3975"
                          data-name="Path 3975"
                          d="M161.082,203.738l-1.827-1.288a.5.5,0,0,0-.788.409v.788H149.7a.5.5,0,1,0,0,1h8.772v.788a.5.5,0,0,0,.788.409l1.827-1.288A.5.5,0,0,0,161.082,203.738Z"
                          transform="translate(-149.195 -202.358)"
                          fill="#00acf1"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_1008"
                      data-name="Group 1008"
                      transform="translate(0 22.397)"
                    >
                      <g
                        id="Group_1007"
                        data-name="Group 1007"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_3976"
                          data-name="Path 3976"
                          d="M14.355,33.4a.5.5,0,0,0-.683.183,6.79,6.79,0,1,1,0-6.789.5.5,0,1,0,.866-.5,7.79,7.79,0,1,0,0,7.791A.5.5,0,0,0,14.355,33.4Z"
                          transform="translate(0 -22.397)"
                          fill="#00acf1"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </v-list-item-icon>
              <v-list-item-title style="line-height: 2">تسجيل الخروج</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-app-bar
        v-if="$route.name != 'SignIn'"
        style="position: absolute; top: 56px; border-top: 1px solid #f8f9fa30"
        id="app_bar"
        app
        :color="$vuetify.theme.themes.light.color2"
        elevation="0"
        class="pb-1"
        height="66"
      >
        <breadcrumbs-component />
        <v-spacer class="hidden-xs-and-down" />
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <!--Handle Errors-->
          <div dir="ltr">
            <v-alert
              dense
              text
              outlined
              type="error"
              class="mx-3"
              dismissible
              v-model="saveErrs.alert"
            >
              <p v-for="(err, i) in saveErrs.error" :key="i">{{ err }}</p>
            </v-alert>
          </div>
          <!--End Handle Errors-->
          <page-component :loading="loading" class="mx-3">
            <template #content>
              <router-view
                v-if="!$store.state.search_result_visibility && !loading"
                @requests="update_app_bar_props($event)"
              />
              <search-result v-else />
              <popup-component />
              <popup-error-component />
              <popup-error-btn-component />
              <!-- <announcement-alert-component /> -->
            </template>
          </page-component>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>

export default {
  name: "App",
  data() {
    return {
      loading: false,
      customer_name: '',
      filtered_search: false,
      arabicErrorMassage: [],
      saveErrs: {
        alert: false,
        error: [],
      },
      loadingNav: false,
      fetched: false,
    };
  },
  async created() {
    if (document.cookie !== "") {
      let user_name = this.user_name();
      this.customer_name = user_name ? user_name["customer_name"] : '';
    }
    this.loading = false;
  },
  methods: {
    logout() {
      this.$store.state.loggedIn = false;
      localStorage.clear();
      document.cookie =
        `wsataJwt=;expires=expires=Sat, 20 Jan 1980 12:00:00 UTC;path=/;domain=` +
        window.location.hostname +
        `;`;
      setTimeout(() => {
        window.location = 'https://alwsata.com.sa/';
      }, 500);
      return;
    },
    user_name() {
      return document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"];
    },
  },
  watch: {
    "$store.state.cardsStyle": {
      handler() {
        this.$store.state.elements = [];
      },
    },
    "$store.state.loggedIn": {
      handler() {
        this.refreshNav();
      },
    },
  },
  mounted() {
  }
};
</script>

<style lang="scss">
.nav__globalFilter {
  border: 1px solid white;
  height: 40px;
  border-radius: 4px;
  color: white;
  padding: 10px;
}

@font-face {
  font-family: "Dintext";
  src: local("Dintext"),
    url(./assets/font/alfont_com_AlFont_com_DINNextLTArabic-Regular.ttf)
      format("truetype");
}

* {
  font-family: Dintext;
}

#app_bar {
  background-image: url(./assets/nav-ground.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.v-main__wrap {
  background: #f3f5f7;
}

.button-colors {
  background-color: #40be72 !important;
  color: white !important;
  font: 15px bold white !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986) !important;
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ced4da;
}

.horiz-dots {
  top: 123px !important;
}

.v-badge {
  position: absolute;
  right: 6px;
  z-index: 10;
}

hr {
  height: 30px !important;
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}

.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: unset !important;
}

.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: unset !important;
}

//Chat style
.chat__footer input:not([type="file"]) {
  padding: 28px 0 28px 69px !important;
}

.chat__footer fieldset {
  border: 1px solid #e2e2e2c9 !important;
  outline: none !important;
}

@media (max-width: 600px) {
  hr {
    display: none !important;
  }

  .separatorr .v-main {
    padding-top: 154px !important;
  }

  .top-spacer {
    display: none;
  }

  .v-toolbar__content,
  .v-toolbar__extension {
    justify-content: space-around;
  }

  .v-toolbar.v-app-bar:last-of-type {
    height: 110px !important;

    .v-toolbar__content {
      flex-direction: column !important;

      div.separatorr {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }

      button {
        img {
          width: 27px !important;
          margin-bottom: 5px;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  .table-toolbar {
    height: 100px !important;

    .v-toolbar__content {
      flex-wrap: wrap !important;

      span {
        font-size: 14px !important;
      }
    }
  }
}
</style>

<template>
  <v-breadcrumbs
    style="color: #ECECEC; font-weight: 500"
    :items="bcItems"
  >
    <template v-slot:divider>
      <span style="color: rgb(229 234 255)">/</span>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  computed: {
    bcItems() {
      if (this.$store.state.bcItems.length) {
        return this.$store.state.bcItems.map((el) => ({
          text: el,
        }));
      }
      return [
        { text: this.$route.meta.group || 'الرئيسية' },
        { text: this.$route.meta.page_name },
      ];
    },
  },
};
</script>

<style>
.v-breadcrumbs li:nth-child(even) {
  padding: 0 5px;
}
.v-breadcrumbs li {
  letter-spacing: 1px;
}
@media(max-width:600px) {
  .v-breadcrumbs  {
    padding: 30px 0 20px 0 !important;
  }
}
</style>

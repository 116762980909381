import Vue from "vue";
import Router from "vue-router";
import {authRouter} from "@/routes/auth.router";
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
      ...authRouter,
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.page_name || "شركة الوساطة العقارية";
  });
  //cookie
  const theCookie = document.cookie
    .split(";")
    .map((coki) => coki.split("="))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key.trim()]: decodeURIComponent(val),
      }),
      {}
    )["wsataJwt"];
  if (theCookie && theCookie != undefined && theCookie != 'null'){
      if (to.fullPath == "/login"){
        next({
          path: '/login',
          query: {
             nextUrl: to.fullPath,
          }
        });
      }
      else if (RegExp('\\blogin\\b').test(to.fullPath)){
        next();
      }
      else if (to.fullPath != "/chats") {
        next('/chats');
      } 
      else {
        next();
      }
  }
  else{
    if (to.fullPath == "/login"){
      next({
        path: '/login',
        query: {
           nextUrl: to.fullPath,
        }
      });
    }
    else if (RegExp('\\blogin\\b').test(to.fullPath)){
      next();
    }
    else
    window.location = 'https://alwsata.com.sa/';
  }
  
});

export default router;

<template>
  <div class="mainContainer">
    <div style="width: 100%; margin-top: 0px !important" class="loginContainer">
      <div style="width: 50%; float: left; padding: 8%;text-align: center;">

        <p
            style="
              margin-top: 50%;
              font-weight: bold;
              font-size: 24px;
            "
          >
            الرجاء الإنتظار إلى حين الإتصال بإخصائي التمويل ...
          </p>
      </div>
      <div
        style="
          margin-left: 50%;
          height: 745px;
          background: #5c315d;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="bg-image img5"></div>
        <img src="@/assets/loginPhoto.svg" style="position: absolute" />
      </div>
    </div>

    <div class="screenMobileMedia">
      <div
        style="
          display: flex;
          width: 100vw;
          height: 285px;
          background-image: linear-gradient(#451546, #814f82);
          position: relative;
          justify-content: center;
        "
      >
        <div class="bg-image img4"></div>
        <div style="position: absolute; top: 10%">
          <img src="@/assets/LayerMobile.svg" />
        </div>
        <div style="position: absolute; top: 30%">
          <img src="@/assets/loginPhotoMobile.svg" />
        </div>
      </div>

      <div style="background: white; border: 1px solid #f0f0f0; padding: 5% ; text-align: center;">

          <p
            style="
              font-weight: bold;
              font-size: 24px;
            "
          >
            الرجاء الإنتظار إلى حين الإتصال بإخصائي التمويل ...
            
          </p>
          <img src="../../assets/Comp-2.gif" class="loader"/>
        

      </div>
    </div>

  </div>
</template>


<script>
import Axios from "axios";
export default {
  data() {
    return {
      customer_id: "",
      customer_mobile: "",
      expires: "",
      signature: "",
    };
  },
  methods: {
    
    async signin() {
      const data = {
        customer_id: this.customer_id,
        customer_mobile: this.customer_mobile,
        expires: this.expires,
        signature: this.signature,
      };
      await Axios.post(
        "https://api.alwsatarealestate.com/api/customer/singleSignOn",
        data,
        {
          headers: {
            "x-api-key":
              "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
          },
        }
      )
        .then((res) => {
          if (res.data.error){
            window.location.href = "https://alwsata.com.sa";
          }
          else{
            let expireTime = new Date();
            expireTime = expireTime.setHours(expireTime.getHours() + 12);
            //console.log(JSON.stringify({'token' : res.data.payload.token, 'customer_id' : res.data.payload.id}))
            document.cookie = `wsataJwt=${JSON.stringify(res.data.payload)};expires=${Date(expireTime)};path=/;domain=`+window.location.hostname+`;`;
            this.$router.push('/chat');
          }
        })
        .catch((error) => {
          window.location.href = "https://alwsata.com.sa";
        });
      },
  },
  mounted() {
  this.customer_id= this.$route.query.customer_id;
  this.customer_mobile= this.$route.query.customer_mobile;
  this.expires= this.$route.query.expires;
  this.signature= this.$route.query.signature;
  if (this.customer_id && this.customer_mobile && this.expires && this.signature)
    this.signin();
  else
    window.location.href = "https://alwsata.com.sa";
  },
};
</script>

<style>
@media only screen and (max-width: 1114px) {
  .mainContainer {
    overflow: hidden;
  }

  .loginContainer {
    display: none;
  }

  .screenMobileMedia {
    display: block;
  }
}

@media only screen and (min-width: 1114px) {
  .loginContainer {
    display: block;
  }

  .screenMobileMedia {
    display: none;
  }
}

.mainContainer__userNameInput {
  background-color: red;
}
.username-err {
  color: darkred;
  font-size: 12px;
  font-weight: 700;
  text-align: end;
  display: block;
  margin-bottom: 20px;
  margin-top: -20px;
}

.loginContainer .v-input__slot {
  border: 1px solid #e7e6e7 !important;
  height: 55px;
  /* padding-right: 1px !important; */
}

.screenMobileMedia .v-input__slot {
  border: 2px solid #ecedec !important;
  height: 55px;
  /* padding-right: 1px !important; */
}
.loader{
  width: 450px;
}

.loginContainer .v-text-field.v-text-field--solo ::placeholder,
.screenMobileMedia .v-text-field.v-text-field--solo ::placeholder {
  font-size: 14px;
  font-weight: bold;
  color: #2f394e;
  padding-right: 10px;
}

.loginContainer .v-icon.v-icon.v-icon--link,
.screenMobileMedia .v-icon.v-icon.v-icon--link {
  color: #926b93 !important;
  margin-bottom: 20px;
}

.loginContainer .v-input__icon,
.screenMobileMedia .v-input__icon {
  visibility: visible !important;
}

.container {
  margin-top: 0px !important;
}

.loginContainer .v-btn__content,
.screenMobileMedia .v-btn__content {
  font-weight: bold;
  font-size: 16px;
}

.loginContainer .content-container,
.screenMobileMedia .content-container {
  margin-top: 0px !important;
}

.loginContainer,
.screenMobileMedia {
  font-family: DIN Next LT Arabic;
}

.bg-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img5 {
  background-image: url("../../assets/scrollGroup.png");
  width: 100%;
}

.img4 {
  background-image: url("../../assets/scrollGroubMobile.png");
  width: 100%;
}
</style>

<template>
  <div>
    <v-app-bar id="app_bar" app color="#2F4395" elevation="5" height="56">
      <v-spacer />
      <v-btn icon color="#ffffff"><v-icon>mdi-magnify</v-icon></v-btn>
      <v-btn icon color="#ffffff"><v-icon>mdi-ticket</v-icon></v-btn>
      <v-btn icon color="#ffffff"><v-icon>mdi-bell-outline</v-icon></v-btn>
      <v-btn icon class="mx-2" small color="#ffffff"
        ><v-icon>mdi-message-outline</v-icon>
      </v-btn>
      <v-avatar class="mx-2" size="36px">
        <img src="@/assets/account.svg" alt="John" />
      </v-avatar>
    </v-app-bar>
    <v-app-bar
      style="position: absolute; top: 56px"
      id="app_bar"
      app
      color="#283D92"
      elevation="0"
      height="60"
    >
      <breadcrumbs-component />
      <v-spacer />
      <v-btn icon color="#ffffff"><v-icon>mdi-microsoft</v-icon></v-btn>
      <v-btn icon color="#ffffff"
        ><v-icon>mdi-format-list-bulleted-square</v-icon></v-btn
      >
      <v-divider style="background-color: #e9edf4" vertical />
      <v-btn fab class="mx-2 mr-4" small color="#ffffff"
        ><v-icon>mdi-printer-outline</v-icon></v-btn
      >
      <v-btn v-if="requests_routes" fab class="mx-2" small color="#ffffff"
        ><v-icon>mdi-filter-outline</v-icon></v-btn
      >
      <v-btn
        height="32px"
        width="50px"
        class="mx-2"
        color="#ffffff"
        @click="printProps"
        ><v-icon color="#3B5DE7">mdi-dots-horizontal</v-icon></v-btn
      >
    </v-app-bar>
  </div>
</template>
<script>
export default {
  peops: ["elements"],
  data() {
    return {};
  },
  computed: {

  },
  methods: {
    printProps(val) {
      this.$store.state.elements = val;
    },
  },
};
</script>

import AutoLogin from "@/views/customer/AutoLogin.vue";
import Chat from "@/views/customer/Chat.vue";

export const authRouter = [
    {
        path: "/login",
        name: "SignIn",
        component: AutoLogin,
        meta: {},
    },
    {
        path: "/chats",
        name: "chats",
        component: Chat,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.574" height="16.641" viewBox="0 0 18.574 16.641">
          <g id="Group_3168" data-name="Group 3168" transform="translate(0.5 0.5)">
            <path id="Path_30" data-name="Path 30" d="M14.228,591.566v-2.294a3.022,3.022,0,0,0-3.022-3.022H4.442a3.022,3.022,0,0,0-3.022,3.022v2.294" transform="translate(-1.42 -575.975)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <circle id="Ellipse_14" data-name="Ellipse 14" cx="3.505" cy="3.505" r="3.505" transform="translate(2.899 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_31" data-name="Path 31" d="M23.58,566.05a3.5,3.5,0,0,1,0,7.01" transform="translate(-12.308 -566.05)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_32" data-name="Path 32" d="M30.47,585.3s3.23.727,2.747,5.8" transform="translate(-15.693 -575.508)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
          </g>
        </svg>
        `,
            listed: true,
            page_name: "المحادثة مع إخصائي التمويل"
        },
    },
]